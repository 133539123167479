
























































import {
	Component,
	Vue
} from "vue-property-decorator"
import {
	apiMpWeChatReplyLists,
	apiMpWeChatReplyDelete,
	apiMpWeChatReplyStatus
} from "@/api/channel/mp_wechat"
import {
	PageMode
} from "@/utils/type"
import {
	RequestPaging
} from "@/utils/util"
// import {apiMpWeChatConfigEdit, apiMPWeChatConfigInfo} from '@/api/channel/mp_wechat'
import LsDialog from "@/components/ls-dialog.vue"
import LsPagination from "@/components/ls-pagination.vue"
@Component({
	components: {
		LsDialog,
		LsPagination
	},
})
export default class keywordReply extends Vue {
	/** S Data **/
	// 分页请求
	pager: RequestPaging = new RequestPaging();
	reply_type = '2' // 回复类型 1-关注回复 2-关键词回复 3-默认回复

	/** E Data **/
	
	/** S Methods **/
	// 获取用户等级列表
	getMpWeChatReplyLists() {
		this.pager
			.request({
				callback: apiMpWeChatReplyLists,
				params: {reply_type: this.reply_type},
			})
			.catch(() => {
				this.$message.error("数据请求失败，刷新重载!");
			});
	}
	// 新增
	onReplyAdd() {
		this.$router.push({
			path: "/channel/mp_wechat/reply/reply_edit",
			query: {
				mode: PageMode["ADD"],
				replyType: this.reply_type,
			},
		});
	}
	// 编辑
	onReplyEdit(item: any) {
		this.$router.push({
			path: "/channel/mp_wechat/reply/reply_edit",
			query: {
				mode: PageMode["EDIT"],
				id: item.id,
				replyType: this.reply_type,
			},
		});
	}
	
	// 修改状态
	putMpWeChatReplyStatus(item: any) {
		apiMpWeChatReplyStatus({
			id: item.id as number
		}).then(() => {
			this.getMpWeChatReplyLists();
			this.$message.success("修改成功!");
		}).catch(() => {
			this.$message.error("修改失败")
		})
	}
	
	// 删除
	onMpWeChatReplyDelete(item: any) {
		apiMpWeChatReplyDelete({
			id: item.id as number
		}).then(() => {
			this.getMpWeChatReplyLists();
			this.$message.success("删除成功!");
		}).catch(() => {
			this.$message.error("删除失败")
		})
	}
	/** E Methods **/
	/** S Life Cycle **/
	created() {
		this.getMpWeChatReplyLists();
	}
	/** E Life Cycle **/
}
